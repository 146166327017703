//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import SelectBtn from "@/components/SelectBtn";
export default {
  name: "tos-page",
  components: {
    BaseLayout,
    SelectBtn,
  },
  metaInfo: {
    title: "MDFF 2",
  },
};
